/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "text-black",
  "headline_variant_default": "",
  "headline_size_default": "text-headline-sm",
  "slide_caption_variant_default": "capitalize",
  "slide_caption_colors_default": "text-gray-600",
  "slide_caption_size_default": "text-headline-xs mt-3 mb-0.5",
  "slide_description_colors_default": "text-black",
  "slide_description_variant_default": "",
  "slide_description_size_default": "text-headline-sm",
  "slide_group_variant_default": "[counter-reset:most-read] flex-nowrap flex-row flex overflow-x-auto overflow-y-hidden snap-x",
  "slide_group_colors_default": "",
  "slide_group_size_default": "gap-3",
  "slide_image_variant_default": "relative after:block after:absolute after:-bottom-8 after:bg-no-repeat after:right-1.5 after:content-[counter(most-read)] after:flex after:items-center after:justify-center after:text-headline-2xl data-[index='0']:after:bg-[url('/image/mostread/blob1.svg')] data-[index='1']:after:bg-[url('/image/mostread/blob2.svg')] data-[index='2']:after:bg-[url('/image/mostread/blob3.svg')] data-[index='3']:after:bg-[url('/image/mostread/blob4.svg')]",
  "slide_image_colors_primary": "after:text-white",
  "slide_image_size_default": "min-w-75 data-[index='0']:after:h-17 data-[index='0']:after:w-20 data-[index='1']:after:h-17 data-[index='1']:after:w-17 data-[index='2']:after:h-16 data-[index='2']:after:w-18 data-[index='3']:after:h-16 data-[index='3']:after:w-16",
  "slide_variant_default": "[counter-increment:most-read] flex flex-col flex-1",
  "slide_colors_default": "",
  "slide_size_default": "basis-0",
  "colors_default": "text-black",
  "variant_default": "flex-col flex overflow-hidden",
  "size_default": "w-full gap-3"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "MostRead");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;